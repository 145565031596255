<template>
  <div>
    <!-- 高级查询 -->
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div style="margin-top:12px;">高级查询&nbsp;</div>
          <div>
            <el-input v-model="input" prefix-icon="el-icon-search"></el-input>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 搜索 -->
    <el-row>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div style="margin-top:12px;">状态&nbsp;&nbsp;</div>
          <!-- 状态 -->
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </el-col>
      <!-- 编号 -->
      <el-col :span="4">
        <div class="grid-content bg-purple-light">
          <div style="margin-top:12px;">编号&nbsp;</div>
          <div>
            <el-input v-model="Numbering" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-col>
      <!-- 占位开始 -->
      <el-col :span="4">
        <div class="grid-content bg-purple">
            <div v-if="receivablesShow">
            <el-select v-model="receivablesValue" placeholder="请选择">
              <el-option
                v-for="item in receivables"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div v-else><el-button type="primary" icon="el-icon-search" plain>搜索</el-button></div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">
            <div v-if="receivablesShow">
            <el-select v-model="receivablesType" placeholder="请选择">
              <el-option
                v-for="item in receivablesTypeValue"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"> <div v-if="receivablesShow">
            <el-button type="primary" icon="el-icon-search" plain>搜索</el-button>
          </div></div>
      </el-col>
      <!-- 占位结束 -->
      <!-- 创建 -->
      <el-col :span="4">
        <div class="grid-content bg-purple-light" v-if="show">
          <div style="margin-left:90%" v-if="shows">
            <el-button type="primary" plain @click="dialogFormVisible=true;" icon="el-icon-circle-plus">创建</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 弹框 -->
    <el-dialog title="创建服务商" :visible.sync="dialogFormVisible">
  <el-form :model="form">
      <el-form-item label="名称" :label-width="formLabelWidth">
      <el-input v-model="form.projectName" auto-complete="off"></el-input>
    </el-form-item>
      <el-form-item label="电话" :label-width="formLabelWidth">
      <el-input v-model="form.phone" auto-complete="off"></el-input>
    </el-form-item>
      <el-form-item label="地址" :label-width="formLabelWidth">
      <el-input v-model="form.address" auto-complete="off"></el-input>
    </el-form-item>
      <el-form-item label="详情" :label-width="formLabelWidth">
      <el-input v-model="form.remark" auto-complete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
    <el-button type="primary" @click="CreateList">确 定</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
import { log } from 'util';
export default {
  props:['show','inputShow','shows','createData'],
  data() {
    return {
      receivablesShow:false,
      input: "", //高级查询value值
      options: [
        {
          value: "选项1",
          label: "可用"
        },
        {
          value: "选项2",
          label: "不可用"
        }
      ],
       receivables: [
        {
          value: "选项1",
          label: "微信"
        },
        {
          value: "选项2",
          label: "支付宝"
        }
      ],
        receivablesTypeValue: [
        {
          value: "选项1",
          label: "主扫"
        },
        {
          value: "选项2",
          label: "被扫"
        }
      ],
      value: "状态", //状态值
      receivablesValue:'微信',//支付方式状态值
      receivablesType:'主扫',//支付类型
      Numbering: "", //编号
      dialogFormVisible: false,//弹框值
      formLabelWidth: '80px',//弹框输入框宽度
      form: {//弹框表单
          projectName: '',
          phone: '',
          remark: '',
          address: '',
        },
    };
  },
  mounted(){
    this.receivablesShow= this.inputShow;
  },
  methods: {
    // 创建工程商
    CreateList(){
      // console.log(this.$emit);
      this.$emit('createData',this.form)
   
    }
  }
};
</script>
<style>
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  display: flex;
}
/* 弹框宽度 */
.el-dialog{
  width: 25%;
}
.el-form-item__label{
  text-align: center !important;
}
</style>

