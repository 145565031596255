<template>
  <div class="app-container">
    <div v-if="switchPag">
      <!-- 查询开始-->
      <div>
        <!--高级搜索输入框开始 -->
        <div v-if="show">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="创建时间">
              <el-date-picker
                v-model="timeValue"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions2"
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <!--高级搜索输入框结束 -->
        <div class="searchheader">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="服务商">
              <el-input v-model="nameValue" placeholder="请输入姓名" :disabled="disableds"></el-input>
            </el-form-item>
            <el-form-item label="服务商号">
              <el-input v-model="projectId" placeholder="请输入服务商号" :disabled="disableds"></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="stateValue" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" round plain @click="search">搜索</el-button>
              <el-button
                @click="showInput"
                plain
                type="primary"
                :icon="show ? 'el-icon-zoom-out':'el-icon-zoom-in'"
                round
                style="border:0;"
              >高级搜索</el-button>
            </el-form-item>
          </el-form>
          <div>
            <el-button
              type="primary"
              plain
              @click="dialogForm=true"
              round
              icon="el-icon-circle-plus"
            >创建</el-button>
          </div>
        </div>
        <!-- 创建工程商弹窗开始 -->
        <!-- 弹框 -->
        <el-dialog title="创建服务商" :visible.sync="dialogForm">
          <el-form :model="addEngineer">
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="projectName"
              :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
            >
              <el-input v-model="addEngineer.projectName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="电话"
              :label-width="formLabelWidth"
              prop="phone"
              :rules="[{ required: true, message: '电话不能为空', trigger: 'blur' }]"
            >
              <el-input v-model="addEngineer.phone" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item
              label="地址"
              :label-width="formLabelWidth"
              prop="address"
              :rules="[{ required: true, message: '地址不能为空', trigger: 'blur' }]"
            >
              <el-input v-model="addEngineer.address" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="详情" :label-width="formLabelWidth">
              <el-input v-model="addEngineer.remark" auto-complete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogForm = false">取 消</el-button>
            <el-button type="primary" @click="addEngineerData">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 创建工程商弹窗结束 -->
      </div>
      <!-- 查询结束 -->
      <!-- 表格开始 -->
      <div>
        <el-table
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          style="width:100%;"
          :height="this.show ?640:700"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="序号" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="服务商名称" width="200">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.projectName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="服务商号" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="150">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="200">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="地址" width="250">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="详情" width="200">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="330" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="EngineerEdit(scope.row)">编辑</el-button>
              <el-button
                size="mini"
                type="warning"
                plain
                @click="EngineerSwitchState(scope.row)"
              >{{scope.row.Disable}}</el-button>
              <el-button size="mini" type="danger" plain @click="DeleteSure(scope.row)">删除</el-button>
              <el-button size="mini" type="success" plain @click="setEngineer(scope.row)">设置</el-button>
              <el-button size="mini" type="success" plain @click="searchMoney(scope.row)">账户余额</el-button>
              <el-button size="mini" type="success" plain @click="getRate(scope.row)">分润费率</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 删除成功提示 -->
        <!-- 删除成功弹窗开始 -->
        <el-dialog title="删除" top="30vh" :visible.sync="dialogVisible" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="EngineerDel">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除成功弹窗结束 -->
        <!-- 编辑弹窗开始  -->
        <el-dialog title="编辑服务商" :visible.sync="dialogFormVisible">
          <el-form :model="form">
            <el-form-item label="* 名称：" :label-width="formLabelWidth">
              <el-input v-model="form.projectName" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="地址：" :label-width="formLabelWidth">
              <el-input v-model="form.address" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="电话：" :label-width="formLabelWidth">
              <el-input v-model="form.phone" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="详情：" :label-width="formLabelWidth">
              <el-input v-model="form.remark" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="创建时间" :label-width="formLabelWidth">
              <el-input v-model="form.createTime" auto-complete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="状态：" :label-width="formLabelWidth">
              <el-input v-model="form.state" auto-complete="off" :disabled="true"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="DetermineEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑弹窗结束 -->

        <!-- 分润费率开始 -->
        <el-dialog title="费率" :visible.sync="EmployeeRote" width="40%">
          <el-table border :data="rateData" style="width:100%;" :row-class-name="tableRowClassName">
            <el-table-column label="渠道">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.channel }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水分润费率">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.yzIsvProfit }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流水分润比例">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.yzIsvProfitPercent }}</span>
              </template>
            </el-table-column>
            <el-table-column label="广告分润比例">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.yzIsvAdPercent }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开启流水分润">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.openYzIsvProfits }}</span>
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" plain @click="rateEdit(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="EmployeeRote = false">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 分润费率结束 -->
        <!-- 编辑费率开始 -->
        <el-dialog title="编辑费率" :visible.sync="roteEdits">
          <el-form :model="roteFormList">
            <el-form-item label="流水分润费率" label-width="100px">
              <el-input v-model="roteFormList.yzIsvProfit" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="流水分润比例" label-width="100px">
              <el-input v-model="roteFormList.yzIsvProfitPercent" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="广告分润比例" label-width="100px">
              <el-input v-model="roteFormList.yzIsvAdPercent" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="开启流水分润" label-width="100px">
              <el-radio-group v-model="roteFormList.openYzIsvProfit">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="roteEdits = false">取 消</el-button>
            <el-button type="primary" @click="confirmEditRote">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 账户余额 开始-->
        <el-dialog title="账户余额" :visible.sync="EmployeeMoney" width="35%">
          <el-table :data="balanceData">
            <el-table-column property="balanceAvailableTotal" label="可用余额"></el-table-column>
            <el-table-column property="balanceFrozenTotal" label="冻结余额"></el-table-column>
            <!-- <el-table-column property="balanceTotal" label="总余额"></el-table-column> -->
          </el-table>
        </el-dialog>
        <!-- 账户余额结束 -->
      </div>
      <!-- 表格结束 -->
      <!-- 分页开始 -->
      <div style="text-align:right">
        <Paging :totalSize="totalSize" :getMethods="searchEngineer" />
      </div>
      <!-- 分页结束 -->
    </div>

    <!-- 设置跳转员工页面开始 -->
    <div v-else>
      <div>
        <div style="text-align:right">
          <el-button size="mini" type="primary" plain @click="ReturnEdit" round>返回</el-button>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="addButton">
        <el-button type="primary" plain @click="addEmployees" round>新增</el-button>
      </div>
      <!-- 创建员工弹框 -->
      <el-dialog title="新增员工" :visible.sync="FormVisible">
        <el-form :model="addPersonnelDatas">
          <el-form-item
            label="名称"
            :label-width="formLabelWidth"
            prop="nickName"
            :rules="[{ required: true, message: '名称不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelDatas.nickName" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="电话"
            :label-width="formLabelWidth"
            prop="mobile"
            :rules="[{ required: true, message: '电话不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelDatas.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="密码"
            :label-width="formLabelWidth"
            prop="password"
            :rules="[{ required: true, message: '密码不能为空', trigger: 'blur' }]"
          >
            <el-input v-model="addPersonnelDatas.password" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="FormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addPersonnels">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 搜索栏结束 -->
      <!-- 表格栏开始 -->
      <div>
        <el-table
          border
          :data="EmployeeData"
          style="width:100%;"
          :height="this.show ?610:670"
          :row-class-name="tableRowClassName"
        >
          <el-table-column label="编号" width="100">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="联系电话" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登录账号" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色" width="180">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.roleId }}</span>
            </template>
          </el-table-column>
          <el-table-column label="性别" width="180">
            <!-- <template slot-scope="scope"> -->
            <span style="margin-left: 10px">男</span>
            <!-- </template> -->
          </el-table-column>
          <el-table-column label="最近登录" width="250">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="298">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="EmployeeEdit(scope.row)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="EmployeeDelete(scope.row)">删除</el-button>
              <el-button size="mini" type="success" plain @click="setPwd(scope.row)">重置密码</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 删除弹窗开始 -->
        <el-dialog title="删除" top="30vh" :visible.sync="dialogVisibleDelete" width="15%">
          <span style="font-size:20px;">你确定要删除此条信息？请继续！</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleDelete = false">取 消</el-button>
            <el-button type="primary" @click="DelSure">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 删除弹窗结束 -->
        <!-- 重置密码弹窗开始 -->
        <el-dialog title="重置密码" top="30vh" :visible.sync="dialogVisibleSet" width="15%">
          <div style="margin-bottom:20px;">
            <el-input placeholder="请输入密码" v-model="pwd" clearable></el-input>
          </div>
          <el-input placeholder="请再次确认密码" v-model="pwdTwo" clearable></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleSet = false">取 消</el-button>
            <el-button type="primary" @click="SureSetPwd">确 定</el-button>
          </span>
        </el-dialog>
        <!-- 重置密码弹窗开始 -->
        <!-- 编辑员工开始 -->
        <el-dialog title="编辑员工" :visible.sync="EmployeeEdits">
          <el-form :model="EmployeesList">
            <el-form-item label="名称" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.nickname" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="电话" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.mobile" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="角色" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.roleId" auto-complete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="* 密码" :label-width="formLabelWidth">
              <el-input v-model="EmployeesList.password" auto-complete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="EmployeeEdits = false">取 消</el-button>
            <el-button type="primary" @click="sureEdit">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 编辑员工结束 -->
      </div>
      <!-- 表格栏结束 -->
    </div>
    <!-- 设置跳转页面结束 -->
  </div>
</template>

<script>
import Query from "../../components/Query/Query.vue";
import Paging from "../../components/Paging/Paging.vue";
import { log } from "util";
import { getToken } from "@/utils/auth";
export default {
  components: {
    Query,
    Paging
  },
  data() {
    return {
      loading: true,
      // 日期选择开始
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      timeValue: null, //工程商选中的日期
      timeValue2: [], //员工界面选中的日期
      // 日期选择结束
      show: false,
      tableData: [], //所有工程商
      EmployeeData: [], //员工
      switchPag: true, //点击设置切换页面
      dialogVisible: false, //删除提示弹窗
      dialogVisibleDelete: false, //删除员工提示弹窗
      dialogVisibleSet: false, //重置密码提示弹窗
      dialogForm: false, //创建工程商弹窗
      form: {}, //编辑弹窗表单
      engineerData: {}, //单挑工程商数据
      Engineedata: {}, //工程商
      Employees: {}, //删除员工数据
      pwd: "", //密码
      pwdTwo: "", //确认密码
      aloneEmployee: {}, //修改密码员工
      EmployeeEdits: false, //编辑员工弹窗
      EmployeesList: {}, //编辑员工数据
      addPersonnelDatas: { nickName: "", mobile: "", password: "" }, //创建员工
      roleId: 0,
      // 设置页面数据
      input: "",
      addEngineer: {
        //创建工程商
        projectName: "",
        phone: "",
        remark: "",
        address: ""
      },
      options: [
        //工程商
        {
          value: "1",
          label: "可用"
        },
        {
          value: "0",
          label: "不可用"
        },
        {
          value: "",
          label: "无"
        }
      ],
      stateValue: "", //工程商状态搜索框
      nameValue: "", //工程商搜索框
      projectId: "", //服务商号搜索框
      receivables: [
        //员工
        {
          value: "1",
          label: "大大"
        },
        {
          value: "2",
          label: ""
        }
      ],

      value: "状态", //状态值
      receivablesValue: "角色", //支付方式状态值
      receivablesType: "主扫", //支付类型
      Numbering: "", //编号
      dialogFormVisible: false, //弹框值
      FormVisible: false, //创建员工弹窗
      formLabelWidth: "80px", //弹框输入框宽度

      totalSize: 0, //总条数,
      disableds: false, //高级搜索打开时姓名输出框是否禁用
      pageDatalist: {},

      rateData: [], //查询的费率
      EmployeeRote: false,

      EmployeeMoney: false, //账户余额
      balanceData: [], //余额数据

      roteFormList: {}, //费率表单值
      roteEdits: false,

      projectId: "" //用于费率编辑时
    };
  },
  methods: {
    //创建工程商
    addEngineerData() {
      this.addEngineer.projectName = this.addEngineer.projectName.trim();
      this.addEngineer.phone = this.addEngineer.phone.trim();
      this.addEngineer.remark = this.addEngineer.remark.trim();
      this.addEngineer.address = this.addEngineer.address.trim();
      if (
        this.addEngineer.projectName !== "" &&
        this.addEngineer.phone !== "" &&
        this.addEngineer.address !== ""
      ) {
        this.$axios
          .get("projectContractor/create", { params: this.addEngineer })
          .then(res => {
            // this.loading=false;
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.searchEngineer(this.pageDatalist);
              this.addEngineer.projectName = "";
              this.addEngineer.phone = "";
              this.addEngineer.remark = "";
              this.addEngineer.address = "";
            } else {
              this.DeleteFailure(res.data.msg);
              this.addEngineer.projectName = "";
              this.addEngineer.phone = "";
              this.addEngineer.remark = "";
              this.addEngineer.address = "";
            }
          });
        this.dialogForm = false;
      } else {
        this.$message.error("请填写完整信息！");
      }
    },

    //编辑服务商
    EngineerEdit(row) {
      this.dialogFormVisible = true; //弹窗显示控制
      this.form = { ...row };
    },
    //确认编辑
    DetermineEdit() {
      if (this.form.projectName !== "") {
        this.$axios
          .get("projectContractor/edit", {
            params: {
              projectId: this.form.id,
              projectName: this.form.projectName.trim(),
              phone: this.form.phone.trim(),
              remark: this.form.remark.trim(),
              address: this.form.address.trim()
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.searchEngineer(this.pageDatalist);
              this.DeleteSuccess();
              this.dialogFormVisible = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    //禁启用工程商
    EngineerSwitchState(row) {
      if (row.Disable === "禁用") {
        this.$axios
          .post("projectContractor/disable?projectId=" + row.id)
          .then(res => {
            if (res.data.state === 1) {
              this.searchEngineer(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      } else if (row.Disable === "启用") {
        this.$axios
          .post("projectContractor/enable?projectId=" + row.id)
          .then(res => {
            if (res.data.state === 1) {
              this.searchEngineer(this.pageDatalist);
              this.DeleteSuccess();
            }
          });
      }
    },
    //删除工程商
    DeleteSure(row) {
      this.dialogVisible = true;
      this.engineerData = row;
    },
    //确认删除工程商
    EngineerDel() {
      this.$axios
        .post("projectContractor/delete?projectId=" + this.engineerData.id)
        .then(res => {
          if (res.data.state == 1) {
            this.DeleteSuccess();
            this.searchEngineer(this.pageDatalist);
            this.dialogVisible = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    // 获取工程商
    searchEngineer(pageData) {
      this.pageDatalist = { ...pageData };
      //时间转换
      let datalist = {
        pageConfig: {
          orderType: "desc",
          pageSize: this.pageDatalist.pageSize,
          orderField: "id",
          pageNum: this.pageDatalist.pageNum
        },
        baseBean:{
          state: this.stateValue,
          id: this.projectId
        }
      };
      // 判断有没有进行高级搜索，有就传入对应的时间，没有就不传
      if (this.timeValue !== null) {
        datalist.searchBeans= [
            //高级查询条件
            {
              fieldName: "createTime", //字段
              startValue: this.timeValue[0] / 1000, //值
              endValue: this.timeValue[1] / 1000,
              operator: "BETWEEN" //大于等于
            },
            {
              fieldName: "state", //字段
              startValue: "0", //值
              operator: "GREATER_THAN_AND_EQUAL" //大于等于
            }
          ]
      } else {
        if (this.nameValue !== "") {//判断是否是模糊查询（模糊查询的输入框的value是否为空)，如果是模糊查询
          datalist.searchBeans= [//高级查询条件
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              },
              {
                fieldName: "projectName", //查询字段
                basicValue: this.nameValue, //值
                operator: "LIKE" //模糊查询
              }
            ]
        } else {//如果不是模糊查询
          datalist.searchBeans= [//高级查询条件
              {
                fieldName: "state", //字段
                startValue: "0", //值
                operator: "GREATER_THAN_AND_EQUAL" //大于等于
              }
            ]
        }
      }
      this.$axios.post("projectContractor/query", datalist).then(res => {
        this.loading = false;
        this.totalSize = res.data.count;
        let datalist = res.data.projectTbPage; //返回数据
        let number =
          (this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
        datalist.map(item => {
          number++;
          item.num = number;
          item.createTime = this.getTiem(item.createTime);
          if (item.state === 1) {
            item.state = "可用";
            item.Disable = "禁用";
          } else if (item.state === 0) {
            item.state = "不可用";
            item.Disable = "启用";
          } else {
            item.state = "禁用";
          }
          return item;
        });
        this.tableData = datalist;
      });
      this.projectId = "";
      this.nameValue = "";
    },
    // 搜索
    search() {
      this.searchEngineer(this.pageDatalist);
    },
    // 账户余额
    async searchMoney(row) {
      let balanceDatalist = await this.$axios.get(
        "/projectContractor/getProjectAccount",
        { params: { projectId: row.id } }
      );
      if (balanceDatalist.data.state == 1) {
        const balance = balanceDatalist.data;
        this.EmployeeMoney = true;
        this.balanceData = [balance.account];
      } else {
        this.$message({
          showClose: true,
          message: balanceDatalist.data.msg,
          type: "error"
        });
      }
    },
    // 分润费率
    async getRate(row) {
      this.projectId = row.id;
      let rateReturnData = await this.$axios.get(
        "/projectContractor/getProjectProfitConfig",
        { params: { projectId: row.id } }
      );
      if (rateReturnData.data.state == 1) {
        const data = rateReturnData.data;
        this.$message({ message: data.msg, type: "success" });
        data.configTb.openYzIsvProfits = data.configTb.openYzIsvProfit
          ? "是"
          : "否";
        data.configTb.channel = "云纵"; //目前是写死的，后面有微信支付宝需要重新定义后端返回的数据格式
        this.rateData = [data.configTb];
        this.EmployeeRote = true;
      } else {
        this.$message({
          showClose: true,
          message: balanceDatalist.data.msg,
          type: "error"
        });
      }
    },
    // 编辑费率
    rateEdit(row) {
      this.roteFormList = { ...row };
      this.roteEdits = true;
    },
    // 确认编辑费率
    confirmEditRote() {
      const {
        yzIsvProfit,
        yzIsvProfitPercent,
        yzIsvAdPercent,
        openYzIsvProfit,
        id
      } = this.roteFormList;
      let params = {
        yzIsvProfit,
        yzIsvProfitPercent,
        yzIsvAdPercent,
        isOpenYzIsvProfit: openYzIsvProfit,
        projectId: id
      };
      if (
        yzIsvProfit !== "" &&
        yzIsvProfitPercent !== "" &&
        yzIsvAdPercent !== ""
      ) {
        this.$axios
          .get("/projectContractor/setProjectProfitConfig", { params })
          .then(res => {
            if (res.data.state == 1) {
              this.getRate(this.projectId);
              this.$message({ message: res.data.msg, type: "success" });
            } else {
              this.$message({ message: res.data.msg, type: "error" });
            }
            this.roteEdits = false;
          });
      }
    },

    // EmployeeRote
    //,员工页面开始
    //页面切换
    setEngineer(data) {
      this.switchPag = false;
      this.Engineedata = data;
      this.getEmployee();
    },
    //获取员工
    getEmployee() {
      this.$axios
        .get("/member/query", {
          params: { projectContractorId: this.Engineedata.id, level: 2 }
        })
        .then(res => {
          let EmployeeList = res.data.userInfoTbPage;
          let number = 0; //定义编号变量
          EmployeeList.map(item => {
            const roleTbMap = res.data.roleTbMap;
            item.newroleId = item.roleId;
            //根据返回的roleId找到对应的name
            const roleId = item.roleId + "";
            item.roleId = roleTbMap[roleId];
            number++;
            item.num = number;
            let unixTimestamp = new Date(item.createTime * 1000); //时间戳转换成日期
            // 定义转换返回方式
            Date.prototype.toLocaleString = function() {
              return (
                this.getFullYear() +
                "年" +
                (this.getMonth() + 1) +
                "月" +
                this.getDate() +
                "日" +
                " " +
                this.getHours() +
                "时" +
                this.getMinutes() +
                "分" +
                this.getSeconds() +
                "秒"
              );
            };
            item.createTime = unixTimestamp.toLocaleString();
            if (item.state === 1) {
              item.state = "可用";
            } else if (item.state === 0) {
              item.state = "不可用";
            } else {
              item.state = "禁用";
            }
            item.loginAccount = getToken().userName;
            return item;
          });
          this.EmployeeData = EmployeeList;
        });
    },
    //删除员工
    EmployeeDelete(row) {
      this.dialogVisibleDelete = true;
      this.Employees = row;
    },
    //确认删除员工
    DelSure() {
      this.$axios
        .post("/member/delete?userId=" + this.Employees.id)
        .then(res => {
          if (res.data.state === 1) {
            this.DeleteSuccess();
            this.getEmployee();
            this.dialogVisibleDelete = false;
          } else {
            this.DeleteFailure(res.data.msg);
          }
        });
    },
    //修改密码
    setPwd(row) {
      this.dialogVisibleSet = true;
      this.aloneEmployee = row;
    },
    //确定修改密码
    SureSetPwd() {
      const { id, mobile, nickname, newroleId } = this.aloneEmployee;
      if (this.pwd === this.pwdTwo && this.pwd !== "") {
        let pwds = this.$md5(this.pwd.trim()).toUpperCase();
        this.$axios
          .get("/member/reSetPw", {
            params: {
              userId: id,
              passWord: pwds.trim()
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.dialogVisibleSet = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.PasswordError();
      }
    },
    // 密码错误提示
    PasswordError() {
      this.$notify.error({
        title: "错误",
        message: "密码错误，请重新输入"
      });
      this.pwd = "";
      this.pwdTwo = "";
    },
    //编辑员工
    EmployeeEdit(row) {
      this.EmployeeEdits = true;
      this.EmployeesList = { ...row };
    },
    //确认编辑
    sureEdit() {
      const { id, mobile, nickname, newroleId, password } = this.EmployeesList;
      if (nickname !== "" && password !== "") {
        this.$axios
          .get("/member/edit", {
            params: {
              roleId: newroleId,
              mobile: mobile.trim(),
              nickName: nickname.trim(),
              userId: id,
              passWord: password.trim()
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.EmployeeEdits = false;
              this.getEmployee();
            } else {
              this.DeleteFailure(res.data.msg);
            }
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    // 返回按钮
    ReturnEdit() {
      this.switchPag = true;
    },
    //创建员工
    addEmployees() {
      this.FormVisible = true;
    },
    //确认创建员工
    addPersonnels() {
      const { nickName, mobile, password } = this.addPersonnelDatas;
      if (nickName !== "" && password !== "" && mobile !== "") {
        let pwds = this.$md5(password.trim()).toUpperCase();
        const { id } = this.Engineedata;
        this.$axios
          .get("/member/create", {
            params: {
              nickName: nickName.trim(),
              mobile: mobile.trim(),
              password: pwds.trim(),
              projectContractorId: id,
              level: 2
            }
          })
          .then(res => {
            if (res.data.state === 1) {
              this.DeleteSuccess();
              this.getEmployee();
              this.FormVisible = false;
            } else {
              this.DeleteFailure(res.data.msg);
            }
            this.addPersonnelData.nickName = "";
            this.addPersonnelData.mobile = "";
            this.addPersonnelData.password = "";
          });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    // 获取所有角色
    searchRole() {
      this.$axios.post("/role/query").then(res => {
        this.receivables = res.data.roleTbPage;
      });
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 高级搜索显示切换
    showInput() {
      this.show = !this.show;
      if (this.show === true) {
        this.disableds = true;
      } else {
        this.disableds = false;
      }
    },

    //操作成功提示
    DeleteSuccess() {
      this.$message({
        message: "恭喜你，操作成功！",
        type: "success"
      });
    },
    //操作失败提示
    DeleteFailure(data) {
      this.$message.error(data);
    },
    //表格颜色显示
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .cell {
  text-align: center;
}
/* 弹窗头部 */

.el-dialog__title {
  color: white !important;
  font-weight: 600;
  font-size: 22px;
}
/* 高级搜索结束 */
.el-button.is-round {
  padding: 10px px;
}
.el-select > .el-input {
  width: 93%;
}
.el-col-4 {
  width: 13.66667%;
}
.el-button--mini {
  padding: 5px 5px;
}
.el-table td {
  padding: 10px 0;
}
.addButton {
  width: 100%;
  text-align: right;
  margin: 15px 0;
}
</style>